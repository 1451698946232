// Add icon for vertical Elipses
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 4.125 17.188"
  >
    <path
      :style="iconStyle"
      class="a"
      d="M1.289-9.711a1.989,1.989,0,0,1,1.461-.6,1.989,1.989,0,0,1,1.461.6,1.989,1.989,0,0,1,.6,1.461,1.989,1.989,0,0,1-.6,1.461,1.989,1.989,0,0,1-1.461.6,1.989,1.989,0,0,1-1.461-.6,1.989,1.989,0,0,1-.6-1.461A1.989,1.989,0,0,1,1.289-9.711Zm0-3.609a1.989,1.989,0,0,1-.6-1.461,1.989,1.989,0,0,1,.6-1.461,1.989,1.989,0,0,1,1.461-.6,1.989,1.989,0,0,1,1.461.6,1.989,1.989,0,0,1,.6,1.461,1.989,1.989,0,0,1-.6,1.461,1.989,1.989,0,0,1-1.461.6A1.989,1.989,0,0,1,1.289-13.32Zm0,13.063a1.989,1.989,0,0,1-.6-1.461,1.989,1.989,0,0,1,.6-1.461,1.989,1.989,0,0,1,1.461-.6,1.989,1.989,0,0,1,1.461.6,1.989,1.989,0,0,1,.6,1.461,1.989,1.989,0,0,1-.6,1.461,1.989,1.989,0,0,1-1.461.6A1.989,1.989,0,0,1,1.289-.258Z"
      transform="translate(-0.688 16.844)"
    />
  </svg>
</template>

<script>
// TODO: This icon is not used anywhere
export default {
  props: {
    height: {
      type: String,
      default: '15',
    },
    width: {
      type: String,
      default: '15',
    },
    color: {
      type: String,
      default: 'menu',
    },
  },
  computed: {
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
